import React from 'react'
import { Tab, Nav, Row, Col } from "react-bootstrap";
import icon_11 from "../../images/icon_11.png";
import icon_12 from "../../images/icon_12.png";
import icon_13 from "../../images/icon_13.png";
import icon_14 from "../../images/icon_14.png";
import icon_15 from "../../images/icon_15.png";
import icon_16 from "../../images/icon_16.png";
import serviceImg_01 from "../../images/services/01.jpg";
import serviceImg_02 from "../../images/services/02.jpg";
import serviceImg_03 from "../../images/services/03.jpg";
import serviceImg_04 from "../../images/services/04.jpg";
import serviceImg_05 from "../../images/services/05.jpg";
import serviceImg_06 from "../../images/services/06.jpg";
export default function ServicesTabs() {
    return (
        <section className="service_wrapper section_padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="service_tab_wrap">
                            <div className="service_tab_nav">
                                <Nav className="nav nav-tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <img src={icon_11} />
                                            <span>Web Solution</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <img src={icon_12} />
                                            <span>Artificial Inteligellence </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <img src={icon_13} />
                                            <span>Web Development</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">
                                            <img src={icon_14} />
                                            <span>App Design</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth">
                                            <img src={icon_15} />
                                                <span>Blockchain</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="six">
                                            <img src={icon_16} />
                                            <span>Data Engineering</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="tab-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>Web Solution</h2>
                                                    <p>You have come to the correct spot if your company needs a creative and polished website.I will design your website as a skilled web developer. I will work one-on-one with </p>
                                                    <p>you to completely comprehend your vision and realise it. I provide you unlimited changes at no additional cost. Our goal is to support you as you grow your company, brand, or portfolio.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_01} className="img-fluid" alt="services"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>Artificial intelligence</h2>
                                                    <p>You have come to the perfect location if you need to learn a lot more about your data or enhance your procedure for your organization.We will strive to develop your model </p>
                                                    <p>so that it can utilize the data, provide better responses, and be significantly more effective for your organization.To accomplish this, we will collaborate with your team </p>
                                                    <p>to fully comprehend your requirements.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_02} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>Web Development</h2>
                                                    <p>You have come to the correct spot if your company needs a creative and polished website.I will design your website as a skilled web developer. I will work one-on-one with </p>
                                                    <p>you to completely comprehend your vision and realise it. I provide you unlimited changes at no additional cost. Our goal is to support you as you grow your company, brand, or portfolio.</p>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_03} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>App Design</h2>
                                                    
                                                    <p>By inheriting the existing corporate colour scheme, typefaces, and graphic style, our UI designers ensure that the app's appearance is compatible with your brand image.</p>
                                                    <p>We do, however, like it if you wish to change the way you look or make a mobile app that stands out graphically. We can't wait to share our own thoughts!</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_04} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>Blockchain</h2>
                                

                                                    <p>Blockchain is revolutionizing how people live and do business, yet it merely serves as a tool to achieve a goal. We can assist you in using this technology to propel your future</p>
                                                    <p> transformation. for this reason Operinova assists clients in building trustworthy, dependable, and sustainable ecosystems for their enterprises. By developing practical business/incentive</p>
                                                    <p> models for all ecosystem participants, we are accelerating the enterprise-wide adoption of blockchain-powered business networks across sectors.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_05} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="six">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="operinova_content_box">
                                                    <h2>Data Engineering</h2>
                                                    <p>You have come to the perfect location if you need to learn a lot more about your data or enhance your procedure for your organization.We will strive to develop your model </p>
                                                    <p>so that it can utilize the data, provide better responses, and be significantly more effective for your organization.To accomplish this, we will collaborate with your team </p>
                                                    <p>to fully comprehend your requirements.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="operinova_img_box">
                                                    <img src={serviceImg_06} className="img-fluid" alt="services" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                     </Tab.Container>
                    </div>
                </div>
            </div>
        </section>
    )
}
