import React from 'react';
import aboutImg from "../../images/about.png";
import { Link } from "react-router-dom";
import "./about.scss";
import { FaPlay } from "react-icons/fa";

export default function Index() {
    const scrollToTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        <section className="operinova_about about_v1">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="operinova_img_box wow fadeInLeft">
                            <img src={aboutImg} className="img-fluid" alt="about"/>
						</div>
                    </div>
                    <div className="col-lg-7">
                        <div className="operinova_content_box wow fadeInRight">
                        <h2>Do you want to improve your Business Performance?</h2>


                        <p>Numerous factors affect productivity, some of which technology may help to enhance and facilitate. The inventive uses of technology by professionals are examined below along with five variables.</p>

                        <p><FaPlay  />  &nbsp;   Communication   </p>
                        <p><FaPlay  />  &nbsp;   Workflows</p>
                        <p><FaPlay  />  &nbsp;   Collaboration</p>
                        <p><FaPlay  />  &nbsp;   Automation</p>
                        <p><FaPlay  />  &nbsp;   Employee engagement</p>
                        <p><FaPlay  />  &nbsp;   Improve business efficiency</p>
                           
                           
                            <Link to="/about" onClick={scrollToTop} className="operinova_btn">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
		</section>
    )
}
