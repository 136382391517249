import React from 'react';
import skillImg from "../../images/skill.png";

export default function Skills() {
    return (
        <section className="operinova_skill">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section_title">
                            <h2>Let Know Our Expert Skills</h2>
                            <p></p>
                        </div>
                        <div className="skill_wrapper">
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>UI/UX Design<span>85%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{width: "85%"}}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Javascript<span>75%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "75%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Dart<span>90%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "90%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>PHP<span>65%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "65%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Nodejs<span>80%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "80%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Python<span>70%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "70%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>SAP<span>80%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "80%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>IOT<span>90%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "80%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>React<span>60%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "60%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Cloud Server<span>70%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "70%" }}><div className="dot"></div></div>
                                </div>
                            </div>

                            <div className="single_bar">
                                <div className="progress_title">
                                    <h5>Data Science<span>75%</span></h5>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" style={{ width: "75%" }}><div className="dot"></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="operinova_img_box">
                            <img src={skillImg} className="img-fluid" alt="skill" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
