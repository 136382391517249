import React from 'react';
import CallToAction from "../call_to_action";
import footerImg from "../../images/footer_bg.png";
import logoImg from "../../images/logo_1.png";
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaPhoneAlt,
    FaEnvelope,
    FaMapMarkerAlt,
    FaLinkedin
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.scss";

export default function Index() {
    const scrollToTop = (e)=> {
        window.scrollTo({
            top: 0
        });
    }
    return (
	  <>
        <CallToAction/>
        <footer className="operinova_footer">
                <div className="widget_wrapper" style={{ backgroundImage: `url(${footerImg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="widget widegt_about">
                                <div className="widget_title">
                                    <Link to="/" onClick={scrollToTop}><img src={logoImg} className="img-fluid" alt="logo" /></Link>
								</div>
                                    <p>Our commitment is innovating</p>
                                    <ul className="social">
                                        <li><a href="#"><i><FaFacebookF/></i></a></li>
                                        <li><a href="#"><i><FaTwitter/></i></a></li>
                                        <li><a href="#"><i><FaInstagram/></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/operinova" target="_blank" rel="noopener noreferrer"><i><FaLinkedin/></i></a></li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_link">
                                    <div className="widget_title">
                                        <h4>Links</h4>
                                    </div>
                                    <ul>
                                        <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
                                        <li><Link to="/services" onClick={scrollToTop}>Services</Link></li>
                                        {/* <li><Link to="/portfolios" onClick={scrollToTop}>Portfolios</Link></li>
                                        <li><Link to="/blog" onClick={scrollToTop}>Portfolios</Link></li> */}
                                        <li><Link to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_contact">
                                    <div className="widget_title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="contact_info">
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaPhoneAlt/></i>
                                            </div>
                                            <div className="info">
                                                <p><a href="tel:+919246147999">+1 305 504-8154</a></p>
                                                <p><a href="tel:+919246147999">+1 437 580-7410</a></p>
                                            </div>
                                        </div>
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaEnvelope/></i>
                                            </div>
                                            <div className="info">
                                                <p><a href="mailto:ldania@operinova.com">ldania@operinova.com</a></p>
                                                
                                            </div>
                                        </div>
                                        <div className="single_info">
                                            <div className="icon">
                                                <i><FaMapMarkerAlt/></i>
                                            </div>
                                            <div className="info">
                                                <p>Salermo Cir ,Weston,Florida</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright_text">
                                    <p>Copyright &copy; 2022 <span>Operinova</span>. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		</footer>
     </>
            
    )
}
