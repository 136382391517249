import React from 'react';

export default function MapLocation() {
    return (
        <section className="operinova_map">
            <div className="map_box">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d66525.30058462275!2d-79.24596135057766!3d43.803454452200924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89f760476f95bf17!2zNDPCsDQ4JzM1LjAiTiA3OcKwMTInNTguMiJX!5e0!3m2!1sen!2sus!4v1658441361269!5m2!1sen!2sus"></iframe>
            </div>
        </section>
    )
}








