import React from 'react';
import aboutImg from "../../images/about.png";
import awardImg from "../../images/award.png";
import signImg from "../../images/sign_2.png";

export default function HireUs() {
    return (
        <section className="operinova_about about_v2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="operinova_img_box">
                            <img src={aboutImg} className="img-fluid" alt="about"/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="operinova_content_box">
                            <h2>Who we are?</h2>
                            <p>The Operinova is a Company funded in 2019 Locate in  Miami/EEUU and Toronto/Canada </p>
                            <p>
                                This team was created focus on learning,using  and developing tech solutions ,
                                We are proud that could count with astonish  group of people that support this company motived and commitment in build innovate solutions for models B2C or B2B.
                            </p>
                        </div>
                        <div className="award_box">
                            <div className="award_img">
                                <img src={awardImg} alt="award"/>
                            </div>
                            <div className="award_info">
                                <img src={signImg} className="img-fluid" alt="signin"/>
                                <h4>Operinova Team</h4>
                                <h5>Best Award 2020</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
