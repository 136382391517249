import React from 'react';
import serviceImg_01 from "../../images/service_1.png";
import serviceImg_02 from "../../images/service_2.png";
import serviceImg_03 from "../../images/service_3.png";
import icon_17 from "../../images/icon_17.png"
import icon_18 from "../../images/icon_18.png"
import icon_19 from "../../images/icon_19.png"

export default function WorkFlow() {

    return (
        <section className="service_feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section_title text-center">
                            <h2>Our Work Flow</h2>
                            <p>When unknow printer took a gallery of type and scramblted it to make a type specimen book</p>
                        </div>
                    </div>
                </div>
                <div className="feature_wrapper">
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="operinova_img_box">
                                    <img src={serviceImg_01} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="operinova_content_box">
                                    <div className="icon">
                                        <img src={icon_17} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Analysis</h3>
                                            <p>we will collect data about your business or idea then we will focus on analysis it this high volume of data </p>
                                            <p>to know the business's core, in order offers the most smarter solution </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="operinova_content_box">
                                    <div className="icon">
                                        <img src={icon_18} alt="icon" />
                                    </div>
                                    <div className="info">
                                        <h3>Development</h3>
                                       
                                            <p>Data collected could be unuseful if we do not use the most appropriate tech and best practices for overcoming barriers.</p>
                                            <p> for this reason, we will use high tech  lastest for developing an innovative solution</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="operinova_img_box">
                                    <img src={serviceImg_02} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single_feature">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="operinova_img_box">
                                    <img src={serviceImg_03} className="img-fluid" alt="service"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="operinova_content_box">
                                    <div className="icon">
                                        <img src={icon_19} alt="icon"/>
                                    </div>
                                    <div className="info">
                                        <h3>Launch</h3>
                                        
                                        <p>Our commitment is to accompany you until the end that this project is over .and much more in  the product launch due you will be vital to demonstrate</p>
                                        <p> and detect if  all challenges were overcome  properly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
